import React from 'react';
import { FaConciergeBell, FaCar, FaUtensils, FaMountain } from 'react-icons/fa'; // Import icons
import videoSrc from '../assets/img/simple-video.mp4'; // Import video

const ServicesIncluded = () => {
  return (
    <div className="bg-white py-16 sm:py-24">
      {/* Background video */}
      <div className="relative w-full h-[400px] overflow-hidden mb-12">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
        />
        {/* Overlay with title on top of the video */}
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold">Details That Make the Difference</h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Services Included
        </h2>
        <p className="mt-4 text-lg text-gray-700">
          We pride ourselves on offering a level of service that goes beyond expectations. From the moment you start planning your trip to the moment you return home, every aspect of your experience is meticulously cared for. We provide a complete range of services designed to ensure your comfort, privacy, and total satisfaction throughout your journey.
        </p>
      </div>
      {/* Services List */}
      <div className="mt-10 max-w-7xl mx-auto grid grid-cols-1 gap-12 lg:grid-cols-2 px-4 sm:px-6 lg:px-8">
        
        {/* Service 1 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <FaConciergeBell className="h-12 w-12 text-[#00aeef] mr-4" />
            <h3 className="text-2xl font-bold text-gray-900">24/7 Private Concierge Service</h3>
          </div>
          <p className="mt-4 text-base text-gray-700 text-justify">
            Our private concierge service is available 24/7 to accommodate any special request. Whether you need to change your itinerary last minute or arrange an exclusive experience, we are always there to assist you.
          </p>
        </div>
        {/* Service 2 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <FaCar className="h-12 w-12 text-[#00aeef] mr-4" />
            <h3 className="text-2xl font-bold text-gray-900">Luxury Transfers</h3>
          </div>
          <p className="mt-4 text-base text-gray-700 text-justify">
            Travel in style with luxury transfers via private cars, helicopters, or private jets, according to your preferences. Your comfort and privacy are always guaranteed during your transfers.
          </p>
        </div>
        {/* Service 3 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <FaMountain className="h-12 w-12 text-[#00aeef] mr-4" />
            <h3 className="text-2xl font-bold text-gray-900">Exclusive Experiences</h3>
          </div>
          <p className="mt-4 text-base text-gray-700 text-justify">
            Enjoy exclusive experiences designed just for you. From private tours in national parks to personalized events in unique locations, every experience is crafted to meet your desires and interests.
          </p>
        </div>
        {/* Service 4 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <div className="flex items-center">
            <FaUtensils className="h-12 w-12 text-[#00aeef] mr-4" />
            <h3 className="text-2xl font-bold text-gray-900">High-End Gastronomy</h3>
          </div>
          <p className="mt-4 text-base text-gray-700 text-justify">
            Delight in high-end with private chefs, dinners in breathtaking settings, and personalized menus tailored to your taste. Every meal is an opportunity to indulge in the finest cuisine.
          </p>
        </div>
      </div>
    </div>
  );
};
export default ServicesIncluded;
