import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Para capturar el ID desde la URL
import videoSrc from '../assets/img/simple-video.mp4'; // Path to the video
import TabMenu from './TabMenu'; 
import SignatureProducts from './SignatureProducts';
import VacationPackages from './StayVacations';

const ProductoSimple = () => {
  const { id } = useParams(); // Captura el ID desde la URL
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Aquí llamamos a la API para obtener el producto según el ID
    const fetchProduct = async () => {
      try {
        const response = await fetch(`https://camino.kuvdigital.com/backend/product/${id}`);
        const data = await response.json();
        setProduct(data); // Guardamos el producto
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [id]);

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="producto-simple-container">
      {/* Background video container */}
      <div className="relative w-full h-[400px] overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
        />
        {/* Superposición de color para el video */}
        <div className="absolute inset-0 bg-black opacity-30"></div>
        {/* Texto en la parte superior del video */}
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold drop-shadow-lg px-4 text-center">
            {product.name} {/* Nombre del producto */}
          </h1>
        </div>
      </div>

      {/* Tab menu and slide */}
      <div className="mt-6">
        <TabMenu /> {/* Render TabMenu component */}
        <SignatureProducts /> {/* Render SignatureProducts component */}
        <VacationPackages /> {/* Render VacationPackages component */}
      </div>
    </div>
  );
};

export default ProductoSimple;
