import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Hook para navegar entre rutas

const SignatureProducts = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate(); // Hook para redirigir

  // Función para obtener los productos específicos
  const fetchSignatureProducts = async () => {
    try {
      const productIds = [91940, 91695, 91693];
      const productPromises = productIds.map(id =>
        fetch(`https://camino.kuvdigital.com/backend/product/${id}`).then(response => response.json())
      );
      const fetchedProducts = await Promise.all(productPromises);
      setProducts(fetchedProducts);
    } catch (error) {
      console.error('Error fetching signature products:', error);
    }
  };

  useEffect(() => {
    fetchSignatureProducts();
  }, []);

  // Función para navegar al detalle del producto
  const handleViewMore = (id) => {
    navigate(`/producto-simple/${id}`);
  };

  return (
    <div className="bg-white px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Exclusive Itineraries</h2>
      <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 p-6 max-w-screen-lg mx-auto">
        {products.map((product, index) => (
          <div key={index} className="group relative bg-white shadow-md rounded-lg p-4">
            <div className="w-full aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none h-60">
              <img
                src={product.images[0]?.src}
                alt={product.name}
                className="w-full h-full object-center object-cover lg:w-full lg:h-full"
              />
            </div>
            <div className="mt-4 flex justify-between">
              <div>
                <h3 className="text-sm text-gray-700">
                  {product.name}
                </h3>
              </div>
            </div>
            <div className="mt-4">
              <button
                onClick={() => handleViewMore(product.id)} // Redirigir al producto con su ID
                className="text-center block bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              >
                View More
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignatureProducts;
