import React, { useState, useEffect } from 'react';
import SignatureProducts from './SignatureProducts'; // Importar SignatureProducts
import videoSrc from '../assets/img/1550080-uhd_3840_2160_30fps.mp4'; // Import your background video

const ImpactfulHeader = () => {
  const [isVisible, setIsVisible] = useState(true); // Initially visible

  useEffect(() => {
    const section = document.querySelector("#impactful-header");

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% of the section must be visible to trigger the animation
    );

    observer.observe(section);

    return () => observer.unobserve(section);
  }, []);

  return (
    <>
      {/* Video Section */}
      <div id="impactful-header" className="relative w-full h-[100vh] bg-cover bg-center">
        {/* Background video */}
        <div className="relative w-full h-full overflow-hidden">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover"
            src={videoSrc}
            autoPlay
            muted
            loop
            playsInline
          />
          {/* Overlay color on video */}
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        {/* Content on top of the video */}
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center z-20 px-6 sm:px-12">
          <h1
            className={`text-white text-4xl sm:text-5xl lg:text-6xl font-bold drop-shadow-lg transition-all duration-500 ${
              isVisible
                ? 'opacity-100 transform translate-y-0'
                : 'opacity-0 transform -translate-y-10'
            }`}
          >
            Discover Authentic Luxury in Costa Rica
          </h1>
          <p
            className={`mt-4 text-xl sm:text-2xl text-white drop-shadow-lg transition-opacity duration-500 ${
              isVisible ? 'opacity-100' : 'opacity-0'
            }`}
          >
            Exclusive experiences designed for the most discerning travelers
          </p>
          <p
            className={`mt-6 text-base sm:text-lg md:text-xl text-white drop-shadow-lg max-w-4xl transition-opacity duration-500 ${
              isVisible ? 'opacity-100' : 'opacity-0'
            }`}
          >
            Imagine waking up in a paradise, surrounded by lush nature, while enjoying all the comforts of a world-class resort. At Camino Travel, we’ve redefined luxury in Costa Rica, blending the country’s natural beauty with impeccable service and unforgettable experiences. From stays in private suites to exclusive dinners on a volcano’s edge, every detail is crafted to meet the highest standards of refinement and sophistication.
          </p>
        </div>

        {/* Gradient fade at the bottom */}
        <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent to-black z-30"></div>
      </div>

      {/* Signature Products Section (Ahora fuera del video) */}
      <div className="w-full bg-gray-100 py-16">
        <SignatureProducts /> {/* Render SignatureProducts AFTER the video section */}
      </div>
    </>
  );
};

export default ImpactfulHeader;
