import React from 'react';
import { FaConciergeBell, FaCar, FaUtensils, FaMountain } from 'react-icons/fa'; // Import icons
import videoSrc from '../assets/img/luxury-video.mp4'; // Import video

const ServicesIncluded = () => {
  return (
    <div className="bg-white py-16 sm:py-24">
      {/* Background video */}
      <div className="relative w-full h-[400px] overflow-hidden mb-12">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
        />
        {/* Superposición de color para el video */}
        <div className="absolute inset-0 bg-black opacity-30"></div>
        {/* Título sobre el video con margen */}
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold drop-shadow-lg mx-4 sm:mx-8">
            Tailored Luxury Journeys Designed Just for You
          </h1>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <p className="mt-4 text-lg text-gray-700 text-justify">
          Every luxury journey with Camino Travel is a masterpiece, uniquely crafted. No two itineraries are alike because we understand that each traveler has unique desires. Whether it’s a private dinner atop a mountain or exclusive guided tours through private reserves, every moment of your journey is designed to offer an unparalleled experience.
        </p>
      </div>

      {/* Services List */}
      <div className="mt-10 max-w-7xl mx-auto grid grid-cols-1 gap-12 lg:grid-cols-2 px-4 sm:px-6 lg:px-8">
        
        {/* Service 1 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Exclusive Stays</h3>
          <p className="text-base text-gray-700 text-justify">
            Enjoy private villas, luxury eco-lodges, or suites with unrivaled panoramic views. Every accommodation is carefully selected for its elegance, privacy, and deep connection to nature.
          </p>

          {/* Icon */}
          <div className="mt-6">
            <FaMountain className="inline-block text-[#00aeef] text-4xl" />
          </div>
        </div>

        {/* Service 2 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Premium Private Transport</h3>
          <p className="text-base text-gray-700 text-justify">
            Whether by private jet, helicopter, or luxury vehicle with a private driver, your comfort and style are assured throughout the trip.
          </p>

          {/* Icon */}
          <div className="mt-6">
            <FaCar className="inline-block text-[#00aeef] text-4xl" />
          </div>
        </div>

        {/* Service 3 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Customized Experiences</h3>
          <p className="text-base text-gray-700 text-justify">
            From gourmet dinners prepared by exclusive chefs in hidden locations to private spa sessions in the middle of the jungle, every moment is designed to cater to your adventurous spirit.
          </p>

          {/* Icon */}
          <div className="mt-6">
            <FaUtensils className="inline-block text-[#00aeef] text-4xl" />
          </div>
        </div>

        {/* Service 4 */}
        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">24/7 Concierge Service</h3>
          <p className="text-base text-gray-700 text-justify">
            Our team is available 24/7 to fulfill any request, whether it’s last-minute itinerary changes or exclusive recommendations.
          </p>

          {/* Icon */}
          <div className="mt-6">
            <FaConciergeBell className="inline-block text-[#00aeef] text-4xl" />
          </div>
        </div>
      </div>

      {/* Featured Images Gallery */}
      <div className="mt-16 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <img src="https://via.placeholder.com/600x400" alt="Service 1" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://via.placeholder.com/600x400" alt="Service 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://via.placeholder.com/600x400" alt="Service 3" className="w-full h-48 object-cover rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default ServicesIncluded;
