// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import { FaBars } from 'react-icons/fa'; // Importar icono de react-icons
import logo from '../assets/img/logo-camino-travel.svg'; // Importamos el archivo SVG
import Drawer from './Drawer'; // Importar el nuevo componente Drawer

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Estado para el drawer
  const navigate = useNavigate(); // Inicializamos useNavigate para la redirección

  // Detectar scroll para cambiar el background
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Función para redirigir a la página de inicio
  const redirectToHome = () => {
    navigate('/'); // Redirige a la ruta de la página principal
  };

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 bg-white ${
        isScrolled ? 'shadow-md' : ''
      }`} // Cambia el fondo a blanco, y agrega una sombra al hacer scroll
    >
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        {/* Logo SVG con ajuste de tamaño en porcentaje y funcionalidad de clic */}
        <div className="flex items-center cursor-pointer" onClick={redirectToHome}>
          <img src={logo} alt="Camino Travel Logo" className="w-[50%]" />
        </div>

        {/* Texto Signature con estilo cursivo en negro */}
        <div>
          <span className="text-black font-signature text-lg md:text-2xl lg:text-3xl italic tracking-wide leading-none">
            Elite Experiences
          </span>
        </div>

        {/* Icono para abrir el Drawer */}
        <div className="ml-auto">
          <FaBars 
            onClick={() => setIsDrawerOpen(true)} // Abrir el drawer
            className="text-black cursor-pointer text-2xl"
          />
        </div>
      </div>

      {/* Drawer Component */}
      <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </header>
  );
};

export default Header;
