import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';  
import Home from './pages/Home'; 
import ProductoSimple from './components/Produtosimple'; // Corregido el nombre
import DestacadosExperiencia from './components/DestacadosExperiencia';
import ItinerariosLujo from './components/ItinerariosLujo';
import MakeDiferents from './components/MakeDiferents'; // Asegúrate de que el nombre del componente esté correcto
import StartPlanningForm from './components/StartPlanningForm';  // Para exportaciones por defecto
import ImpactfulHeader from './components/ImpactfulHeader';
import ScrollToTopButton from './components/ScrollToTopButton'; // Ruta al archivo del botón
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <Header />

      {/* Mueve el botón fuera de las rutas para que esté siempre visible */}
      <ScrollToTopButton /> 

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/producto-simple/:id" element={<ProductoSimple />} /> {/* Ruta con parámetro de ID */}
        <Route path="/destacados-experiencia" element={<DestacadosExperiencia />} />
        <Route path="/itinerarios-lujo" element={<ItinerariosLujo />} />
        <Route path="/make-diferents" element={<MakeDiferents />} /> {/* Ruta Make Diferents actualizada */}
        <Route path="/start-planning" element={<StartPlanningForm />} />
        <Route path="/impact-header" element={<ImpactfulHeader />} />
      </Routes> 

      <Footer />
    </Router>
  );
};

export default App;
