import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import videoSrc from "../assets/img/1550080-uhd_3840_2160_30fps.mp4"; // Asegúrate de tener un video en tu carpeta de assets


const StartPlanningForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    city: "",
    country: "",
    phone: "",
    adults: "",
    teenagers: "",
    children: "",
    lengthOfStay: "",
    accommodationType: "",
    transportMeans: "",
    travelGoals: [],
    experiences: [],
    activities: [],
    vacationBudget: "",
    additionalInfo: "",
    consent: false,
  });
  const [currentStep, setCurrentStep] = useState(1);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox" && Array.isArray(formData[name])) {
      if (checked) {
        setFormData({
          ...formData,
          [name]: [...formData[name], value],
        });
      } else {
        setFormData({
          ...formData,
          [name]: formData[name].filter((item) => item !== value),
        });
      }
    } else if (type === "checkbox" && name === "consent") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="relative h-screen overflow-hidden">
      {/* Video de fondo */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        src={videoSrc}
        autoPlay
        muted
        loop
        playsInline
      />

      {/* Superposición oscura para mejorar la legibilidad */}
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

      {/* Contenido del formulario */}
      <div className="relative z-20 flex items-center justify-center h-full max-h-[90vh]">
        <div className="bg-white shadow-md p-8 rounded-lg w-full max-w-3xl overflow-y-auto max-h-[90vh]">
          <form className="space-y-8" onSubmit={handleSubmit}>
        {/* Step 1: Contact Information */}
        {currentStep === 1 && (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Contact Information</h2>
            <p>Please provide your contact information so we can reach out to you with more details about your trip.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                  First Name *
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                  Last Name *
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  City *
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Country *
                </label>
                <input
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Phone *
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 2: Travel Details */}
        {currentStep === 2 && (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Travel Details</h2>
            <p>Provide us with details about your travel plans to help us customize your trip.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="adults" className="block text-sm font-medium text-gray-700">
                  Adults (+18) *
                </label>
                <input
                  type="number"
                  name="adults"
                  value={formData.adults}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="teenagers" className="block text-sm font-medium text-gray-700">
                  Teens (ages 12-17)
                </label>
                <input
                  type="number"
                  name="teenagers"
                  value={formData.teenagers}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="children" className="block text-sm font-medium text-gray-700">
                  Children (under 12)
                </label>
                <input
                  type="number"
                  name="children"
                  value={formData.children}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label htmlFor="lengthOfStay" className="block text-sm font-medium text-gray-700">
                  Length of stay *
                </label>
                <select
                  name="lengthOfStay"
                  value={formData.lengthOfStay}
                  onChange={handleChange}
                  required
                  className="mt-1 p-2 w-full border rounded-md"
                >
                  <option value="">Choose...</option>
                  <option>Up to 1 week</option>
                  <option>1-2 weeks</option>
                  <option>2-3 weeks</option>
                  <option>More than 3 weeks</option>
                </select>
              </div>
              <div>
                <label htmlFor="accommodationType" className="block text-sm font-medium text-gray-700">
                  Preferred type of accommodation
                </label>
                <select
                  name="accommodationType"
                  value={formData.accommodationType}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md"
                >
                  <option value="">Choose...</option>
                  <option>Standard</option>
                  <option>Luxury</option>
                  <option>Eco-friendly</option>
                  <option>Budget</option>
                </select>
              </div>
              <div>
                <label htmlFor="transportMeans" className="block text-sm font-medium text-gray-700">
                  Preferred means of transportation
                </label>
                <select
                  name="transportMeans"
                  value={formData.transportMeans}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md"
                >
                  <option value="">Choose...</option>
                  <option>Private transfers, driver only</option>
                  <option>Shared transfers</option>
                  <option>Rental car</option>
                  <option>Public transportation</option>
                </select>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Previous
              </button>
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 3: Main Travel Goals */}
        {currentStep === 3 && (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Main Travel Goals</h2>
            <p>Select your main travel goals.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="travelGoals"
                    value="Highlights of Costa Rica"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Highlights of Costa Rica</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="travelGoals"
                    value="Leisure and relaxation"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Leisure and relaxation</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="travelGoals"
                    value="Nature and wildlife"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Nature and wildlife</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="travelGoals"
                    value="Celebrate a special occasion"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Celebrate a special occasion</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="travelGoals"
                    value="Adventure activities"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Adventure activities</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="travelGoals"
                    value="Family getaway"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Family getaway</span>
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Previous
              </button>
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 4: Preferred Experiences */}
        {currentStep === 4 && (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Definitely Want to Experience</h2>
            <p>Select what you definitely want to experience.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="experiences"
                    value="National parks"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">National Parks</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="experiences"
                    value="Beaches"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Beaches</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="experiences"
                    value="Rainforest / Cloud forest"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Rainforest / Cloud forest</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="experiences"
                    value="Volcanoes"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Volcanoes</span>
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Previous
              </button>
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 5: Activities */}
        {currentStep === 5 && (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Activities and Adventures</h2>
            <p>Select the activities and adventures you are interested in.</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="activities"
                    value="Wildlife observation"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Wildlife Observation</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="activities"
                    value="Hiking"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Hiking</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="activities"
                    value="Whitewater rafting"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Whitewater Rafting</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="activities"
                    value="Surfing"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Surfing</span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="activities"
                    value="Zip lining"
                    onChange={handleChange}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">Zip Lining</span>
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Previous
              </button>
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>
        )}

        {/* Step 6: Final Information */}
        {currentStep === 6 && (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">Tell Us More About Your Trip</h2>
            <p>Share any additional information or specific requests you have for your trip.</p>
            <div className="space-y-4">
              <div>
                <label htmlFor="vacationBudget" className="block text-sm font-medium text-gray-700">
                  Your vacation budget
                </label>
                <select
                  name="vacationBudget"
                  value={formData.vacationBudget}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border rounded-md"
                >
                  <option value="">Choose...</option>
                  <option>Standard</option>
                  <option>Luxury</option>
                  <option>Budget</option>
                </select>
                <p className="text-sm text-gray-500">
                  Normally, the selected price range applies to lodging, food, and transportation elements.
                </p>
              </div>
              <div>
                <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700">
                  Tell us more about your trip
                </label>
                <textarea
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                  rows="3"
                  className="mt-1 p-2 w-full border rounded-md"
                />
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="consent"
                    onChange={handleChange}
                    required
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="ml-2">
                    I agree to give my personal information, according to Data Protection Regulation.
                  </span>
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                onClick={prevStep}
              >
                Previous
              </button>
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
                Submit
              </button>
            </div>
          </div>
        )}
       </form>
        </div>
      </div>
    </div>
  );
};

export default StartPlanningForm;