// src/components/Drawer.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import logo from '../assets/img/logo-camino-travel.svg'; // Importamos el archivo SVG

const Drawer = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  // Función para redirigir a la página de inicio
  const redirectToHome = () => {
    onClose(); // Cerrar el drawer
    navigate('/'); // Redirige a la ruta de la página principal
  };

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
    >
      <div className={`fixed top-0 right-0 w-64 h-full bg-white p-4 transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex items-center mb-4">
          <img src={logo} alt="Camino Travel Logo" className="w-32" />
        </div>
        <ul>
          <li onClick={redirectToHome} className="cursor-pointer py-2">Inicio</li>
          <li onClick={onClose} className="cursor-pointer py-2">Destacados de Experiencia</li>
          <li onClick={onClose} className="cursor-pointer py-2">Itinerarios de Lujo</li>
          <li onClick={onClose} className="cursor-pointer py-2">Servicios Incluidos</li>
          <li onClick={onClose} className="cursor-pointer py-2">Planificación</li>
          {/* Agrega más elementos según sea necesario */}
        </ul>
        <button onClick={onClose} className="mt-4">Cerrar</button>
      </div>
    </div>
  );
};

export default Drawer;
