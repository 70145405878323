import React, { useEffect, useState } from "react";

const TabMenu = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [productContent, setProductContent] = useState('');
  const [productName, setProductName] = useState('');
  const [itineraryContent, setItineraryContent] = useState('');
  const [includedDetails, setIncludedDetails] = useState('');
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    const fetchProductContent = async () => {
      try {
        const response = await fetch('https://camino.kuvdigital.com/backend/product/91695');
        const product = await response.json();

        const cleanedContent = product.description.replace(/<\/?p[^>]*>/g, '');
        setProductContent(cleanedContent);
        setProductName(product.name);

        const itineraryMeta = product.meta_data.find(meta => meta.key === '_kuv_itinerary');
        if (itineraryMeta) {
          const cleanedItineraryContent = itineraryMeta.value.replace(/<\/?p[^>]*>/g, '');
          setItineraryContent(cleanedItineraryContent);
        }

        const includedMeta = product.meta_data.find(meta => meta.key === '_kuv_what_is_include_trip_details');
        if (includedMeta) {
          const cleanedIncludedDetails = includedMeta.value.replace(/<\/?p[^>]*>/g, '');
          setIncludedDetails(cleanedIncludedDetails);
        }

        const images = product.images;
        setGalleryImages(images);

      } catch (error) {
        console.error('Error fetching product content:', error);
      }
    };

    if (activeTab === 0 || activeTab === 1 || activeTab === 2 || activeTab === 4) {
      fetchProductContent();
    }
  }, [activeTab]);

  const tabs = [
    {
      title: "Details",
      content: (
        <div>
          <h2 className="text-xl font-bold mb-4">{productName || 'Loading...'}</h2>
          <div
            className="text-gray-700 text-justify"
            dangerouslySetInnerHTML={{ __html: productContent || 'Loading product content...' }}
          />
        </div>
      ),
    },
    {
      title: "Itinerary",
      content: (
        <div>
          <h2 className="text-xl font-bold mb-4">Itinerary</h2>
          <div
            className="text-gray-700 text-justify"
            dangerouslySetInnerHTML={{ __html: itineraryContent || 'No itinerary available.' }}
          />
        </div>
      ),
    },
    {
      title: "What's Included",
      content: (
        <div>
          <h2 className="text-xl font-bold mb-4">What's Included</h2>
          <div
            className="text-gray-700 text-justify"
            dangerouslySetInnerHTML={{ __html: includedDetails || 'No details available.' }}
          />
        </div>
      ),
    },
    {
      title: "Route Overview",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Route Overview content.",
    },
    {
      title: "Photos",
      content: (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {galleryImages.length > 0 ? (
            galleryImages.map((image, index) => (
              <img key={index} src={image.src} alt={`Product visual ${index + 1}`} className="w-full h-48 object-cover" />
            ))
          ) : (
            <p>No images available.</p>
          )}
        </div>
      ),
    },
    {
      title: "Important",
      content: (
        <div>
          <h2 className="text-xl font-bold mb-4">Important notes about this vacation package</h2>
          <ul className="list-disc list-inside text-gray-700 space-y-2 text-justify">
            <li>Price is based on a minimum of 2 people occupancy.</li>
            <li>Local taxes are included in prices.</li>
            <li>Accommodations will be in the above-mentioned hotels or alternative properties of similar style and ambience, according to availability at the time of booking.</li>
            <li>Tours on the package may be adapted according to traveler preference.</li>
            <li>The itinerary can be adjusted based on the arrival and departure airports (San Jose/SJO or Guanacaste/LIR).</li>
            <li>You will receive a 100% accurate price when you inquire with us and share your travel dates.</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full mx-auto mt-8 px-4">
      {/* Menú de pestañas centrado */}
      <div className="flex justify-center mb-4">
        <div className="flex flex-wrap space-x-2 border-b border-gray-200">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`px-4 py-2 sm:px-6 sm:py-3 text-sm font-medium focus:outline-none transition-colors duration-200 ${
                activeTab === index
                  ? "bg-[#00aeef] text-white"
                  : "bg-white text-black hover:bg-gray-100"
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>

      {/* Contenido de la pestaña activa */}
      <div className="mt-6 p-6 max-w-screen-lg mx-auto bg-white shadow-sm">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default TabMenu;
