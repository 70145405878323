import React, { useEffect } from 'react';

const Section7 = () => {
  useEffect(() => {
    const section = document.querySelector("#section7");

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Here was the setIsVisible, but we removed it
      },
      { threshold: 0.5 } // Activates when at least 50% of the section is visible
    );

    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) {
        observer.unobserve(section);
      }
    };
  }, []);

  return (
    <div
      id="section7"
      className="relative w-screen h-auto bg-white text-black transition-opacity duration-500 ease-in-out opacity-100" // White background and black text
    >
      {/* FAQ Section Content */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="lg:grid lg:grid-cols-2 lg:gap-12">
          {/* Supporting text */}
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Frequently Asked Questions
            </h2>
            <p className="mt-4 text-lg text-gray-700">
              Can’t find the answer you’re looking for? Reach out to our{" "}
              <a href="#" className="text-[#00aeef]">
                customer support team.
              </a>
            </p>
          </div>

          {/* FAQ Questions */}
          <div className="mt-12 lg:mt-0 lg:col-span-1">
            <div className="space-y-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  How are itineraries personalized?
                </h3>
                <p className="mt-2 text-base text-gray-700">
                  At Camino Travel, we understand that every luxury traveler has unique expectations. Our team works with you from the first contact to understand your preferences and desires, from types of accommodation to special activities. From there, we create a fully personalized itinerary, tailored to your needs. You can modify any aspect of the trip, from transportation to experiences, and you will receive a final proposal that guarantees an unforgettable experience.
                </p>
              </div>

              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  What types of accommodations are available?
                </h3>
                <p className="mt-2 text-base text-gray-700">
                  We offer a selection of the best luxury accommodations in Costa Rica. You can choose from five-star resorts, private villas, luxury eco-lodges, and boutique accommodations. All hotels and properties are selected for their level of exclusivity, premium services, and unique location, whether on the beach, in the jungle, or in the mountains.
                </p>
              </div>

              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Can an itinerary be changed during the trip?
                </h3>
                <p className="mt-2 text-base text-gray-700">
                  Absolutely. Our private concierge service is available 24 hours during your trip to accommodate any changes or requests. If you decide to change activities, add an experience, or make adjustments on the fly, our team will handle the necessary changes seamlessly, always ensuring your comfort and satisfaction.
                </p>
              </div>

              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  What is the private transportation process like?
                </h3>
                <p className="mt-2 text-base text-gray-700">
                  Transportation in our luxury itineraries is done in premium vehicles, always ensuring maximum comfort and privacy. We offer transfers in luxury cars, helicopters, or private jets, depending on your preferences and the itinerary's characteristics. From your arrival to your departure, every transfer will be scheduled so you can enjoy a seamless and stress-free journey.
                </p>
              </div>

              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  What security and privacy measures are offered for luxury travelers?
                </h3>
                <p className="mt-2 text-base text-gray-700">
                  The safety and privacy of our clients is our top priority. We work with trusted providers that offer a discreet and high-level service. Accommodations, transportation, and activities are selected with privacy in mind, and you will always have personalized assistance to ensure a secure and protected environment.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Gradient fade at the bottom */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent to-white z-30"></div>
    </div>
  );
};

export default Section7;
