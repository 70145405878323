import { RiArrowDownDoubleLine } from "react-icons/ri";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import backgroundImage from '../assets/img/image2.jpg'; // Import background image

const Section2 = () => {
  const [isVisible, setIsVisible] = useState(true); // Initially visible
  const navigate = useNavigate(); // Inicializamos useNavigate

  useEffect(() => {
    const section = document.querySelector("#section2");

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% of the section must be visible to trigger the animation
    );

    observer.observe(section);

    return () => observer.unobserve(section);
  }, []);

  const scrollToNextSection = () => {
    setIsVisible(false); // Trigger exit animation
    setTimeout(() => {
      const nextSection = document.getElementById("section3");
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 500); // Wait 500ms for the exit animation
  };

  const redirectToDestacados = () => {
    navigate('/destacados-experiencia'); // Redirigir a la página de "Destacados de la Experiencia"
  };

  return (
    <div
      id="section2"
      className={`relative w-screen h-[100vh] bg-cover bg-center transition-opacity duration-500 ease-in-out ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Content with animation */}
      <div className="absolute inset-0 flex items-center justify-center z-20">
        <div className="text-left px-4 md:px-10">
          {/* Fade Side Up Animation for Title */}
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white drop-shadow-lg transition-all duration-500 ${
              isVisible
                ? 'opacity-100 transform translate-y-0'
                : 'opacity-0 transform -translate-y-10'
            }`}
          >
            Luxury Travel Tailored to Your Needs
          </h1>
          {/* Fade Side Left Animation for Subtitle */}
          <p
            className={`mt-4 text-sm sm:text-lg md:text-xl lg:text-2xl text-white drop-shadow-lg transition-all duration-500 ${
              isVisible
                ? 'opacity-100 transform translate-x-0'
                : 'opacity-0 transform -translate-x-10'
            }`}
          >
            We create unique experiences tailored to your preferences, allowing you to enjoy each destination in the best way.
          </p>
          {/* Fade Side Down Animation for Button */}
          <button
            onClick={redirectToDestacados} // Agrega la función de redirección al botón
            className={`mt-6 px-4 py-2 md:px-6 md:py-3 border-2 border-white text-white hover:bg-white hover:text-black transition-all duration-500 ${
              isVisible
                ? 'opacity-100 transform translate-y-0'
                : 'opacity-0 transform translate-y-10'
            }`}
          >
            More Information
          </button>
        </div>
      </div>

      {/* Scroll down icon */}
      <div
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 z-30 cursor-pointer text-white animate-bounce"
        onClick={scrollToNextSection}
      >
        <RiArrowDownDoubleLine size={40} />
      </div>

      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent to-[#030712] z-30"></div>

      <div className="absolute inset-0 bg-black opacity-30"></div>
    </div>
  );
};

export default Section2;
