import React, { useState, useEffect } from 'react';

// Import local images
import img1 from '../assets/img/vacations1.jpg';
import img2 from '../assets/img/vacations2.jpg';
import img3 from '../assets/img/vacations3.jpg';
import img4 from '../assets/img/vacations4.jpg';
import img5 from '../assets/img/vacations5.jpg';
import img6 from '../assets/img/vacations6.jpg';

// Array de imágenes locales
const backgroundImages = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
];

const VacationPackages = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % backgroundImages.length);
    }, 9000); // Cambia la imagen cada 9 segundos
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative text-white overflow-hidden max-h-[400px] md:max-h-[400px] lg:max-h-[661px] mt-[90px]">
      {/* Contenedor de imágenes de fondo */}
      <div className="absolute inset-0 z-[-1]">
        {backgroundImages.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
              currentImage === index ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={image}
              alt={`Background ${index}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>

      {/* Contenido del paquete de vacaciones */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-[400px] md:min-h-[400px] lg:min-h-[661px] text-center px-4">
        <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-4">Vacation Packages</h1>
        <div className="border-b-2 border-yellow-500 w-16 mb-6"></div>
        <p className="text-sm md:text-lg mb-8 max-w-md mx-auto">
          DESIGN UNIQUE TRAVEL EXPERIENCES IN COSTA RICA
        </p>
        <button className="mt-8 bg-yellow-500 text-white py-3 px-6 rounded hover:bg-yellow-600">
          Create My Own Itinerary
        </button>
      </div>
    </div>
  );
};

export default VacationPackages;
