import React from "react";
import logo from '../assets/img/logo-camino-travel-footer.svg'; // Ruta correcta del archivo SVG
import { FaFacebookF, FaInstagram, FaYoutube, FaEnvelope } from "react-icons/fa"; // Import social icons

const Footer = () => {
  return (
    <footer className="bg-[#00aeef] text-white py-12">
      <div className="container mx-auto px-6 md:px-12 flex flex-col items-center text-center">
        {/* Logo y descripción centrados */}
        <div className="mb-6">
          <img
            src={logo}  // Usamos la variable importada "logo"
            alt="Camino Travel Logo"
            className="h-10 mb-4 mx-auto" // Logo centrado
          />
          <p className="text-sm">
            Discover Costa Rica with the best tour operator, offering unique and luxury experiences tailored to your needs.
          </p>
        </div>

        {/* Redes sociales */}
        <div className="flex space-x-4 mt-4">
          <a href="https://www.facebook.com/CaminoTravel" className="text-white hover:text-gray-300">
            <FaFacebookF size={20} />
          </a>
          <a href="https://www.instagram.com/caminotravel" className="text-white hover:text-gray-300">
            <FaInstagram size={20} />
          </a>
          <a href="https://www.youtube.com/user/CaminoTravelCostaRica" className="text-white hover:text-gray-300">
            <FaYoutube size={20} />
          </a>
          <a href="mailto:info@caminotravel.com" className="text-white hover:text-gray-300">
            <FaEnvelope size={20} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
