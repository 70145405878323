import { RiArrowDownDoubleLine } from "react-icons/ri";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importa Link para navegación
import backgroundImage from '../assets/img/image6.jpg'; // Import background image

const Section6 = () => {
  const [isVisible, setIsVisible] = useState(true); // Initially visible

  useEffect(() => {
    const section = document.querySelector("#section6");

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% of the section must be visible to trigger the animation
    );

    observer.observe(section);

    return () => observer.unobserve(section);
  }, []);

  // Function to scroll to the next section (Section 7)
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("section7");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      id="section6"
      className={`relative w-screen h-[100vh] bg-cover bg-center transition-opacity duration-500 ease-in-out ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Content with animation */}
      <div className="absolute inset-0 flex items-center justify-center z-20">
        <div className="text-left px-4 md:px-10">
          {/* Fade Side Up Animation for Title */}
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white drop-shadow-lg transition-all duration-500 ${
              isVisible
                ? 'opacity-100 transform translate-y-0'
                : 'opacity-0 transform -translate-y-10'
            }`}
          >
            Let Us Create Your Dream Luxury Trip
          </h1>
          {/* Subtitle (customize the text) */}
          <p
            className={`mt-4 text-lg sm:text-xl md:text-2xl lg:text-3xl text-white drop-shadow-lg transition-all duration-500 ${
              isVisible
                ? 'opacity-100 transform translate-x-0'
                : 'opacity-0 transform -translate-x-10'
            }`}
          >
            Experience luxury and exclusivity in every moment of your journey
          </p>
          {/* Descriptive text */}
          <p
            className={`mt-6 text-sm sm:text-lg md:text-xl lg:text-2xl text-white drop-shadow-lg transition-opacity duration-500 ${
              isVisible ? 'opacity-100' : 'opacity-0'
            }`}
          >
            Are you ready to immerse yourself in a personalized luxury journey in Costa Rica? Our team of experts is here to make your adventure and comfort dreams come true. Contact us today and let us plan an exclusive experience you will remember forever.
          </p>
          {/* Fade Side Down Animation for Button */}
          <Link to="/start-planning">
            <button
              className={`mt-6 px-4 py-2 md:px-6 md:py-3 border-2 border-white text-white hover:bg-white hover:text-black transition-all duration-500 ${
                isVisible
                  ? 'opacity-100 transform translate-y-0'
                  : 'opacity-0 transform translate-y-10'
              }`}
            >
              Contact Us to Design Your Luxury Trip
            </button>
          </Link>
        </div>
      </div>

      {/* Scroll down icon */}
      <div
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 z-30 cursor-pointer text-white animate-bounce"
        onClick={scrollToNextSection} // Connect to Section 7
      >
        <RiArrowDownDoubleLine size={40} />
      </div>

      {/* Gradient fade at the bottom */}
      <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-b from-transparent to-[#030712] z-30"></div>

      {/* Semi-transparent dark overlay */}
      <div className="absolute inset-0 bg-black opacity-30"></div>
    </div>
  );
};

export default Section6;
