import React from 'react';
import videoSrc from '../assets/img/luxury-video.mp4'; // Path to the video
import { FaBed, FaCar, FaUtensils, FaConciergeBell } from 'react-icons/fa'; // Import icons

const LuxuryExperienceHighlights = () => {
  return (
    <div className="relative bg-white py-16 sm:py-24">
      {/* Background Video */}
      <div className="relative w-full h-[400px] overflow-hidden mb-12">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          muted
          loop
          playsInline
        />
        {/* Color overlay on the video */}
        <div className="absolute inset-0 bg-black opacity-30"></div>
        {/* Title over the video with margin */}
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold drop-shadow-lg mx-4 sm:mx-8">
            Tailored Luxury Journeys Designed Just for You
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <div className="max-w-3xl mx-auto">
          <p className="mt-4 text-lg text-gray-700 text-justify">
            Every luxury journey with Camino Travel is a masterpiece, uniquely crafted. No two itineraries are alike because we understand that each traveler has unique desires. Whether it’s a private dinner atop a mountain or exclusive guided tours through private reserves, every moment of your journey is designed to offer an unparalleled experience.
          </p>
        </div>

        {/* Highlighted Features Section */}
        <div className="mt-10">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-2">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <FaBed className="h-12 w-12 text-[#00aeef]" />
              </div>
              <div className="ml-4 text-justify">
                <h3 className="text-lg font-medium text-gray-900">Exclusive Stays</h3>
                <p className="mt-2 text-base text-gray-500">
                  Enjoy private villas, luxury eco-lodges, or suites with unrivaled panoramic views. Every accommodation is carefully selected for its elegance, privacy, and deep connection to nature.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex-shrink-0">
                <FaCar className="h-12 w-12 text-[#00aeef]" />
              </div>
              <div className="ml-4 text-justify">
                <h3 className="text-lg font-medium text-gray-900">Premium Private Transport</h3>
                <p className="mt-2 text-base text-gray-500">
                  Whether by private jet, helicopter, or luxury vehicle with a private driver, your comfort and style are assured throughout the trip.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex-shrink-0">
                <FaUtensils className="h-12 w-12 text-[#00aeef]" />
              </div>
              <div className="ml-4 text-justify">
                <h3 className="text-lg font-medium text-gray-900">Customized Experiences</h3>
                <p className="mt-2 text-base text-gray-500">
                  From gourmet dinners prepared by exclusive chefs in hidden locations to private spa sessions in the middle of the jungle, every moment is designed to cater to your adventurous spirit.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <div className="flex-shrink-0">
                <FaConciergeBell className="h-12 w-12 text-[#00aeef]" />
              </div>
              <div className="ml-4 text-justify">
                <h3 className="text-lg font-medium text-gray-900">24/7 Concierge Service</h3>
                <p className="mt-2 text-base text-gray-500">
                  Our team is available 24/7 to fulfill any request, whether it’s last-minute itinerary changes or exclusive recommendations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LuxuryExperienceHighlights;
